<template>
  <div> 
    <!-- Backend Errors -->
    <div v-if="isError === true">
      <div v-for="error in errors" :key="error">
        <div v-for="el in error" :key="el">
          <ion-item>
            <div tabindex="0"></div><!-- bug fix for safari 14 -->
            <ion-text color="danger">{{el}}</ion-text>
          </ion-item>
        </div>
      </div>
    </div>
    <!-- Front End Errors -->
    <ion-item v-if="emailMisMatch === true">
      <div tabindex="0"></div><!-- bug fix for safari 14 -->
      <ion-text color="danger">Both email fields must match</ion-text>
    </ion-item>
    <ion-item v-if="email === null || email == ''">
      <div tabindex="0"></div><!-- bug fix for safari 14 -->
      <ion-text color="danger">The email field is required.</ion-text>
    </ion-item>
    
    <!-- Title -->
    <div class="ion-padding-bottom">
      <h4>Email</h4>
      <ion-text>Enter the email for your account</ion-text>
    </div>
    <!-- Form -->
    <ion-item class="ion-text-center">
      <div tabindex="0"></div><!-- bug fix for safari 14 -->
      <ion-input v-model="email" type="email" placeholder="Enter Email"></ion-input>
    </ion-item>
    <ion-item class="ion-text-center">
      <div tabindex="0"></div><!-- bug fix for safari 14 -->
      <ion-input v-model="confirmEmail" type="email" placeholder="Confirm Email"></ion-input>
    </ion-item>
    <!-- Submit -->
    <ion-item v-if="emailValidated === true" class="ion-text-center">
      <div tabindex="0"></div><!-- bug fix for safari 14 -->
      <ion-button @click.prevent="storeProfileEmail()">
        Save
      </ion-button>
      <ion-button color="light" @click.prevent="resetForm()">
        Reset
      </ion-button>
    </ion-item>

  </div>
</template>

<script>
import { IonText, IonInput, IonItem, IonButton, loadingController, toastController } from '@ionic/vue';
import { defineComponent, onMounted, ref } from 'vue';
//import { person } from 'ionicons/icons';
import apiClient from '../services/api';

export default defineComponent({
  name: 'ProfileEmail',
  props: ['store'], 
  components: {
    IonText, IonInput, IonItem, IonButton
  },
  setup(props) {
    const isError = ref(false);
    const errors = ref([]);
    const emailMisMatch = ref(false);    
    const email = ref(null)
    const confirmEmail = ref(null);
    const emailValidated = ref(false);
    const timeout = { default: 1000 }
    const toastMessage = ref(null);

    onMounted(() => {
      if(props.store.authUser.email != null) {
        email.value = props.store.authUser.email;
      }
    })

    function resetForm() {
      emailValidated.value = false
      isError.value = false
      errors.value = []
      emailMisMatch.value = false
      email.value = null
      confirmEmail.value = null
    }

    async function presentLoading() {
      const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Please wait...',
          duration: timeout,
        });

      await loading.present();

      setTimeout(function() {
        loading.dismiss()
      }, timeout);
    }

    async function openToast() {
      const toast = await toastController
        .create({
          //message: '',
          duration: 6000,
          message: toastMessage.value,
          position: 'bottom',
          buttons: [
            {
              icon: 'close',
              role: 'cancel',
              handler: () => {
                
              }
            }
          ]
        })
      return toast.present();
    }

    function storeProfileEmail() {
      presentLoading()
      apiClient.post('/api/update/profile-email', 
        {
          email: email.value           
        },
        {
          headers: {
            'Authorization':  `Bearer ${props.store.authToken}`
          }
        }).then(response => {
          // Success
          if(response.data.message == 'success') {
            localStorage.setItem('authUser', JSON.stringify(response.data.user));
            this.dataUp(response.data.user)
            toastMessage.value = 'You have updated your profile.'
            openToast()
            isError.value = false
            errors.value = []
          }
          // Fail Validation
          if(response.data.message == 'fail-not-changed') {
            toastMessage.value = 'You must change your email before you can save it.'
            openToast()          
          }
          // Fail Validation
          if(response.data.message == 'fail') {
            toastMessage.value = 'Something went wrong.'
            openToast()          
          }
          // Fail Validation
          if(response.data.message == 'fail-validation') {
            toastMessage.value = 'Something went wrong.'
            openToast()
            isError.value = true
            errors.value = response.data.errors            
          }
          // Fail Auth
          if(response.data.message == 'fail-auth') {
            toastMessage.value = 'You must be signed in to update your profile.'
            openToast()
          }
        }).catch(error => {
          console.log(error)  
          // credentials didn't match
          isError.value = true
          errors.value = error.response.data.errors
      });       
    }

    return {
      isError, errors, emailMisMatch, email, confirmEmail, emailValidated, presentLoading, storeProfileEmail, resetForm
    }
  },
  methods: {
    dataUp: function(user) {
      var componentData = {
        dataType: 'user',
        data: user
      }

      this.$emit('component-data', componentData)
    }
  },
  watch: {
    email: function() {
      // Both fields have a value
      if(this.email != null && this.email != '' && this.confirmEmail != null && this.confirmEmail != null) {
        if(this.email != this.confirmEmail) {
          this.emailMisMatch = true
          this.emailValidated = false
        } else {
          this.emailValidated = true
          this.emailMisMatch = false
        }
      } 
      // Both fields do not have value
      else {
        this.emailMisMatch = false
      }
      if(this.confirmEmail === null || this.confirmEmail == '') {
        this.emailMisMatch = false
        this.emailValidated = false
      }
    },
    confirmEmail: function() {
      // Both fields have a value
      if(this.email != null && this.email != '' && this.confirmEmail != null && this.confirmEmail != null) {
        if(this.email != this.confirmEmail) {
          this.emailMisMatch = true
          this.emailValidated = false
        } else {
          this.emailValidated = true
          this.emailMisMatch = false
        }
      } 
      // Both fields do not have value
      else {
        this.emailMisMatch = false
      }
      if(this.confirmEmail === null || this.confirmEmail == '') {
        this.emailMisMatch = false
        this.emailValidated = false
      }
    }
  }
});
</script>