<template>
  <ion-page>
    <!-- Page Content -->
    <ion-content>
      <!-- Header -->
      <ModalHeader page="short-description" />
      <div class="app-max-width padding-left-md padding-right-md padding-bottom-md">
        <ProfileEmail :store="store.state" @component-data="getData" />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';
import doAuth from '../../services/auth';
import ModalHeader from '../../components/ModalHeader.vue';
import ProfileEmail from '../../components/ProfileEmail.vue'
import { useStore } from "vuex";
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'ProfileEmailAdmin',
  components: {
    IonContent, IonPage, ModalHeader, ProfileEmail
  },
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()

  },
  setup() {
    const store = useStore();
    const router = useRouter();

    function updateUserData(user) {
      store.commit('setAuthUser', user);
    }

    function goBack() {
      router.go(-1)
    }

    return {
      store, updateUserData, router, goBack
    }
  },
  methods: {
    getData: function(e) {
      this.updateUserData(e.data)
      this.goBack()
    },
  },
});
</script>

<style scoped>

</style>
